/* eslint-disable */
// Example:
// namespace('sitevision.design.js').App = function () {
//   this.update = function(){};
//   var test = function(){};
// };
// var test = new sitevision.design.js.App();
// test.update()

const nPxMobileBreakPoint = 800; // 784;

function resize(nWindowW) {
    const nRatio = 0.7421875; // default-ratio kadi-layout
    const nRatioSmall = 0.5625; // ratio für weniger hohe zeilen (video-inhalt zb.)
    const nRatioHeight = parseInt(nWindowW / 2 * nRatio);
    const nRatioHeightMobile = parseInt(nWindowW * nRatio);
    const bSmallScreen = (nWindowW <= nPxMobileBreakPoint);
    const bIE8 = !!($('html').hasClass('lt-ie9'));

    // --- default resize ---
    // -> mobile: mindest-höhe anwenden, sonst ratio nehmen
    const defaultMinHeight = (bSmallScreen) ? nRatioHeightMobile : nRatioHeight;
    $('.sv-view .content-box').height(defaultMinHeight);
    $('.kadi-col.kadi-product-desc').css({ 'min-height': `${defaultMinHeight}px` });
    // ---

    // 2-col-layout resize (zeilen mit bild & text)
    $('.sv-view .kadi-row').each(function () {
        const row = $(this);
        const rowImg = row.find('div.kadi-col > img, div.kadi-col > a img');
        const rowTxtBox = row.find('.content-box');
        let rowTxtBoxHeight = (row.hasClass('small')) ? parseInt(nWindowW / 2 * nRatioSmall) : nRatioHeight;

        if (rowImg.length > 0) {
            if (bSmallScreen) {
                // beim wechsel auf das mobile-layout, resize-styling entfernen
                rowImg.removeClass('resized');
                row.removeAttr('style');
            } else {
                // grösser als mobile
                // bildergalerie: bilder nicht als background umbauen:
                if (!(rowImg.hasClass('orbit'))) {
                    if (!bIE8) {
                        // Bild als Hintergrund setzen
                        rowImg.addClass('resized');
                        $(this).css({ 'background-image': `url('${rowImg.attr('src')}')` });
                        // Bild als Spalteinhalt rechts umsetzen
                        if (!(rowImg.closest('.kadi-col').is(':first-child'))) {
                            $(this).css({ 'background-position': 'right' });
                        }
                    }

                    // bild-ratio prüfen
                    rowImgRatio = rowImg.height() / rowImg.width();
                    if (rowImgRatio > nRatio) {
                        rowTxtBoxHeight = parseInt(nWindowW / 2 * rowImgRatio);
                    }
                }

                // mobile-features für produkte entfernen
                row.find('> div').removeClass('hide');
            }
        }

        if (!bSmallScreen) { rowTxtBox.height(rowTxtBoxHeight); }
    });

    // mobile: memory
    if (bSmallScreen) {
        $('.modern-or-not-ie div.product-flip-container').each(function () {
            const prodRow = $(this);
            // mindest-höhe (layout) oder content-höhe übernehmen
            const contentH = prodRow.find('div.kadi-product-desc > div').outerHeight();
            prodRow.height(contentH > defaultMinHeight ? contentH : defaultMinHeight);
            // im wechsel vprder- und rückseite bestimmen
            const evenOrOdd = (prodRow.index() % 2 == 0) ? 'even' : 'odd';
            prodRow.find(`div.kadi-col:nth-child(${evenOrOdd})`).addClass(function () {
                $(this).siblings('div.kadi-col').addClass('front');
                return 'back';
            });
            // bild als hintergrund anhängen
            // var prodRowImage = prodRow.find('.kadi-product-image > img');
            // if (prodRowImage.length > 0) {
            //   prodRow.find('.kadi-product-image').css({ "background-image": "url('"+prodRowImage.attr('src')+"')" });
            // }
        });
    } else {
        $('div.product-flip-container, .kadi-product-image').removeAttr('style');
    }

    // content-list: portraits
    if (!bIE8) {
        $('.sv-view .kadi-row-contentlist .kadi-col > div.portrait').each(function () {
            const oPortraitImg = $(this).find('img');
            oPortraitImg.addClass('resized');
            $(this).parent().css({ 'background-image': `url('${oPortraitImg.attr('src')}')` });
        });
    }
}

$(window).resize(() => {
    // body.width und nicht window-width (scrollbars) übergeben
    resize($('body').width());
});

function setTopbarHeight(nLines, nItemHeight) {
    // 25: default line height
    nItemHeight = (nItemHeight === 0) ? 25 : nItemHeight;
    const nHeight = nLines * nItemHeight;
    // 90 als default
    $('div#top').height(nHeight + 90);
}

$(() => {
    // top-bar: bg-height
    $('.top-bar-section ul.left > li').mouseover(e => {
        const sender = (e && e.target) || (window.event && window.event.srcElement);
        const oCurMenuLink = $(sender);
        const nCurMenuLinkIdx = oCurMenuLink.parent().prevAll('.sv-menu').length;
        const oChildList = oCurMenuLink.siblings('ul.dropdown');

        if (oChildList.length > 0) {
            const oCurList = oCurMenuLink.parent().parent();
            const oChildListChildren = oChildList.children('.sv-menu');
            const nChildListSize = oChildListChildren.length;
            const nChildListItemHeight = $(oChildListChildren[0]).height();
            if (!oCurList.hasClass('dropdown')) {
                // hover root-menu
                setTopbarHeight(nChildListSize, nChildListItemHeight);
            } else {
                // hover sub-menu
                let nDiff = nChildListSize - oCurList.children('.sv-menu').length;
                nDiff = (nDiff > 0) ? nDiff : 0;
                setTopbarHeight(oCurList.children('.sv-menu').length + nDiff, nChildListItemHeight);
                // childs nach oben verschieben
                if (nCurMenuLinkIdx > 0) {
                    const nOffset = nChildListItemHeight * nCurMenuLinkIdx;
                    oChildList.css({ 'margin-top': `-${nOffset}px`, 'padding-bottom': `${nOffset}px` });
                }
            }
        }
    });

    $('.top-bar-section').mouseleave(() => {
        $('div#top').removeAttr('style');
    });

    $('div#topnav-box .expand').click(() => {
        $('div#topnav-box .expand').hide();
        $('div#topnav-box .content').show('slide', { direction: 'right' });
    });

    $('div#topnav-box .collapse').click(() => {
        $('div#topnav-box .content').hide('slow', () => {
            $('div#topnav-box .expand').show();
        });
    });

    function postToUrl(url, params, method, target) {
        // create form
        const form = $('<form/>').attr({method: method || "post", action: url});
        // add target
        if (target) {
            form.attr('target', target);
        }
        // append parameters
        $.each(params, function (key, value) {
            $('<input type="hidden"/>').attr('name', key).val(value).appendTo(form);
        });
        // submit form
        form.appendTo('body').submit();
    }

    $('#language-nav a.click').click(function (event) {
        event.preventDefault();
        postToUrl(document.location, { sv_lang: $(this).attr('rel'), sv_lang_change: true });
    });

    // products: flip over data-/image-view
    $('div.kadi-row-product div.flip-corner').bind('click', function (event) {
        $(this).closest('div.kadi-row-product').toggleClass('flip');
    });

    $('div.partner-bar img, .footer-logo .list img').hover(
        function () {
            const sHoverSrc = $(this).attr('src').replace('.png', '_Farbig.png');
            $(this).attr({ src: sHoverSrc });
        }, function () {
            const sHoverSrc = $(this).attr('src').replace('_Farbig.png', '.png');
            $(this).attr({ src: sHoverSrc });
        },
    );

    // video
    const $allVideos = $('.sv-view .kadi-video iframe');
    const $fluidEl = $('.sv-view .kadi-video');

    $allVideos.each(function () {
        $(this)
            // jQuery .data does not work on object/embed elements
            .attr('data-aspectRatio', this.height / this.width)
            .removeAttr('height')
            .removeAttr('width');
    });

    $(window).resize(() => {
        const newWidth = $fluidEl.width();
        $allVideos.each(function () {
            const $el = $(this);
            $el
                .width(newWidth)
                .height(newWidth * $el.attr('data-aspectRatio'));
        });
    }).resize();

    // top-link nur anzeigen, wenn gescrollt wird
    toggleBackToTopLink();
    $(window).scroll(() => {
        toggleBackToTopLink();
    });

    const menuWithDropdown = document.querySelectorAll('.top-bar-section .sv-menu.has-dropdown');
    menuWithDropdown.forEach(menu => {
        menu.addEventListener('click', () => {
            const link = menu.querySelector('a');
            location.href = link.getAttribute('href');
        });
    });
});

function toggleBackToTopLink() {
    ($(window).scrollTop() > 102) ? $('#scroll-top').show() : $('#scroll-top').hide();
}

/** function to open the modal box for the inscription to the event* */
function ShowIFrameModal(title, url) {
    const code = $('input[name=code]').val();

    const iframe = $('<iframe>')
        .attr({
            src: url + code,
            width: '100%',
            height: '650',
            frameborder: '0',
        });

    // --- dialog-div ---
    const oFormDialog = $('#dialog')
        .append(iframe).dialog({
            title,
            autoOpen: true,
            position: 'top',
            width: 650,
            height: 740,
            modal: true,
            resizable: true,
            close() {
                // Div (Form) wieder aus Body entfernen
                $(this).empty();
            },
        });
    // ---
}

function openWin(url) {
    const code = $('input[name=code]').val();
    const width = 800;
    const height = 600;
    const left = Math.floor((screen.availWidth - width) / 2);
    const top = Math.floor((screen.availHeight - height) / 2);
    const windowFeatures = `width=${width},height=${height
    },location=no,toolbar=no,titlebar=no,menubar=no,scrollbars=yes,resizable=yes,`
            + `left=${left},top=${top
            }screenX=${left},screenY=${top}`;
    window.open(url + code, '', windowFeatures);
}
