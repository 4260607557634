/* eslint-disable import/first */
import '../scss/app.scss';

import 'foundation-sites/js/foundation/foundation';
import 'foundation-sites/js/foundation/foundation.topbar';
import 'foundation-sites/js/foundation/foundation.equalizer';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';

// jQuery
const jQueryTemp = require('jquery');
// .min version throws error with Foundation
const jQuery = jQueryTemp;
window.$ = jQueryTemp;
window.jQuery = jQueryTemp;
jQuery.migrateMute = true;

// node_modules
import 'jquery-ui/ui/effects/effect-slide';
import 'jquery-placeholder/jquery.placeholder';

import './app/app';
import './app/rem.min';
import '../../../platform/widgets/view/news/js/base';
import '../../../platform/widgets/view/participants/js/participants';
import '../../../platform/widgets/view/news/js/swiper';

import '../vendor/lightbox/js/lightbox';

/* eslint-disable prefer-arrow-callback */
$(document).ready(function () {
    const $placeholderElements = $('.sv-widget input, textarea');
    if ($placeholderElements.length > 0) {
        $placeholderElements.placeholder();
    }

    $(document).foundation();
});
