$(() => {
    const $likeButton = $('.js-news-like');
    const $likeCount = $likeButton.find('.js-news-like-count');
    const elvId = $likeButton.data('article-id');
    const $shareBtn = $('.js-news-share');
    const $shareLinks = $('.js-news-share-links');

    const $showOldButton = $('.js-news-show-old');
    const $oldArticlesSection = $('.js-news-old');

    if (window.localStorage.getItem(`kadi-news-${elvId}-liked`) !== null) {
        document.querySelector(`.js-news-like[data-article-id='${elvId}'] .icon-like`).classList.add('hide');
        document.querySelector(`.js-news-like[data-article-id='${elvId}'] .icon-liked`).classList.remove('hide');
        $likeCount.removeClass('hide');
        $likeButton.html($likeButton.html().replace('Like', 'Liked'));
    }

    $likeButton.on('click', () => {
        if (window.localStorage.getItem(`kadi-news-${elvId}-liked`) === null) {
            $.ajax({
                type: 'POST',
                url: $likeButton.data('like-url'),
                success: data => {
                    if (typeof data.error !== 'undefined' && data.error === false) {
                        window.localStorage.setItem(`kadi-news-${elvId}-liked`, 'true');
                        $likeCount.text(`${data.likes}`);
                        $likeCount.removeClass('hide');
                    }
                },
            });

            document.querySelector(`.js-news-like[data-article-id='${elvId}'] .icon-like`).classList.add('hide');
            document.querySelector(`.js-news-like[data-article-id='${elvId}'] .icon-liked`).classList.remove('hide');
        }
    });

    $shareBtn.on('click', () => {
        $shareLinks.toggle('hide');
    });

    $showOldButton.on('click', () => {
        $oldArticlesSection.toggle('hide');
    });
});
