import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

document.addEventListener('DOMContentLoaded', () => {
    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper('.mySwiper', {
        modules: [Navigation, Pagination],
        slidesPerGroup: 3,
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 0px
            0: {
                slidesPerGroup: 1,
                slidesPerView: 1,
                spaceBetween: 20,
            },
            // when window width is >= 640px
            640: {
                slidesPerGroup: 2,
                slidesPerView: 2,
                spaceBetween: 20,
            },
            // when window width is >= 960px
            960: {
                slidesPerGroup: 3,
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    });
});
